
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"0aa5d21022ba6afb23c0a3ca4611073bfe352665":"$$ACTION_0","af741c2f26c03b2ae64bac85145bc4923355fa63":"logout"} */ export var logout = createServerReference("af741c2f26c03b2ae64bac85145bc4923355fa63");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

